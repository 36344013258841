@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply bg-white text-primary;
  }
}

@layer components {
  .btn-primary {
    @apply px-6 py-3 bg-accent text-white rounded-lg hover:bg-opacity-90 transition-all;
  }

  .section-padding {
    @apply py-20 px-4 md:px-8 lg:px-16;
  }
}
